import { useContext, useEffect, useState } from 'react';
import { PulppoDrawer } from '../Elements/PulppoDrawer';
import { UserContext } from '../../contexts/UserContext';
import axios from '../../utils/axios';
import { wapp_url } from '../../helpers/fetcher';
import { PulppoLoader, PulppoNewLoader } from '../Elements/PulppoLoader';
import WhatsappIcon from '../Icon/WhatsappIcon';
import MenuIcon from '../Icon/MenuIcon';
import SettingsIcon from '../Icon/SettingsIcon';
import Alert from '../Closes/Alert';
import { LoadingButton } from '../Elements/ButtonLoading';
import useUser from '../../hooks/useUser';
import Button from '../Elements/Button';
import dayjs from 'dayjs';
import { Spin } from 'antd';
import { PhoneInputPlain } from '../Elements/PhoneInputPlain';
import useIsMobile from '../../hooks/isMobile';

const WhatsappInactive = ({ onClose }) => {
    const user = useUser((u) => u.user);
    const [show, setShow] = useState(true);
    const [qr, setQr] = useState(null);
    const { setUser } = useContext(UserContext);
    const [askedForCode, setAskedForCode] = useState<Date | null>(null);
    const [code, setCode] = useState<string | null>(null);
    const [phone, setPhone] = useState<string | null>(user?.phone);
    const [step, setStep] = useState<'phone' | 'code' | 'qr' | 'success'>('phone');

    useEffect(() => {
        const askedForCode = localStorage.getItem('askedForCode');
        if (askedForCode) {
            setAskedForCode(new Date(askedForCode));
        }
    }, []);

    useEffect(() => {
        if (!user?.phone) return;
        setPhone(user?.phone);
    }, [user?.phone]);

    useEffect(() => {
        if (!user?.uid) return;
        if (!user?.features?.['whatsapp-disconnect']) return;
        if (askedForCode && dayjs(askedForCode).isAfter(dayjs().subtract(1, 'hour'))) {
            const interval = setInterval(async () => {
                const code = await axios.get(`${wapp_url}/code/${user?.uid}`).then((r) => r?.data.code);
                if (code) {
                    setCode(code);
                    clearInterval(interval);
                }
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [user?.uid, askedForCode, user?.features]);
    const isMobile = useIsMobile();
    useEffect(() => {
        if (!user?.uid) return;
        if (user?.features?.['whatsapp-disconnect'] && isMobile) return;
        const interval = setInterval(async () => {
            const qr = await axios
                .get(`${wapp_url}/qr/${user?.uid}`, { timeout: 1000 })
                .then((r) => r?.data.qr)
                .catch(() => null);
            setQr(qr);

            const exists = await axios
                .get(`${wapp_url}/status/${user?.uid}`, {
                    timeout: 1000
                })
                .then((r) => r?.data.status === 'open')
                .catch(() => null);
            if (exists) {
                setUser((u) => ({ ...u, whatsapp: true }));
                onClose?.();
                clearInterval(interval);
            }
        }, 3000);
        return () => {
            clearInterval(interval);
        };
    }, [user?.uid, isMobile]);

    return (
        <PulppoDrawer
            title="WhatsApp inactivo"
            visible={show}
            onClose={() => {
                setShow(false);
                onClose();
            }}
        >
            {user?.features?.['whatsapp-disconnect'] ? (
                <div className="flex h-full flex-col md:hidden">
                    <p className="mb-4 font-semibold">Tu WhatsApp se encuentra desvinculado de la plataforma</p>
                    <p className="mb-4">
                        Es importante mantener vinculado tu WhatsApp para atender con facilidad y rapidez a potenciales
                        clientes, y hacer el correcto seguimiento de tus clientes en sincronización con la plataforma
                    </p>

                    {step === 'phone' ? (
                        <div className="flex flex-1 flex-col items-center justify-center">
                            <PhoneInputPlain value={phone} onChange={setPhone} className="py-2 text-lg" />
                        </div>
                    ) : (
                        <div className="flex min-h-[1px] flex-1 flex-col items-center justify-center">
                            <Spin spinning={!code} className="">
                                <div className="mx-auto mb-4 flex items-center justify-evenly gap-2 rounded-md border border-solid border-gray-200 p-2">
                                    {(code || '____-____').split('').map((c, i) => (
                                        <div className="p-2 text-2xl" key={i}>
                                            {c}
                                        </div>
                                    ))}
                                </div>
                            </Spin>
                        </div>
                    )}
                    {step === 'code' && (
                        <>
                            <p className="font-semibold">Vincula WhatsApp en tu teléfono</p>

                            <div className="mt-2 flex flex-col gap-2">
                                {[
                                    <p key={1} className="flex">
                                        1. Abre{' '}
                                        <span className="mx-1">
                                            <WhatsappIcon />
                                        </span>{' '}
                                        WhatsApp en tu teléfono principal.
                                    </p>,
                                    <p key={2} className="flex flex-wrap">
                                        2. Pulsa
                                        <span className="mx-1">
                                            <MenuIcon />
                                        </span>{' '}
                                        Menú o
                                        <span className="mx-1">
                                            <SettingsIcon />
                                        </span>{' '}
                                        Configuración y selecciona &quot;Dispositivos vinculados&quot;
                                    </p>,

                                    <p key={3} className="flex">
                                        3. Selecciona &quot;Vincular con el número de teléfono&quot;.
                                    </p>,
                                    <p key={1} className="flex">
                                        4. Ingresa el código que ves en esta pantalla.
                                    </p>
                                ].map((text, index) => (
                                    <div key={index}>{text}</div>
                                ))}
                            </div>
                        </>
                    )}
                    <div className="mb-6 flex items-center justify-center">
                        <a
                            className="text-center font-semibold underline"
                            href="https://ayuda.pulppo.com/es/articles/6882186-whatsapp-recopilacion-de-datos"
                            target="_blank"
                            rel="noreferrer"
                        >
                            ¿Cómo utilizamos la información de tu WhatsApp?
                        </a>
                    </div>

                    <LoadingButton
                        disabled={!phone || phone?.length < 12}
                        type="primary"
                        className="h-auto w-full py-2"
                        onClick={async () => {
                            setAskedForCode(new Date());
                            localStorage.setItem('askedForCode', new Date().toISOString());
                            await axios.post(`${wapp_url}/code/${user?.uid}`, { phone });
                            setStep('code');
                        }}
                    >
                        Solicitar código de WhatsApp
                    </LoadingButton>
                </div>
            ) : (
                <div className="flex h-full flex-col md:hidden">
                    <p className="mb-4 font-semibold">Tu WhatsApp se encuentra desvinculado de la plataforma</p>
                    <p className="mb-4">
                        Es importante mantener vinculado tu WhatsApp para atender con facilidad y rapidez a potenciales
                        clientes, y hacer el correcto seguimiento de tus clientes en sincronización con la plataforma
                    </p>

                    <Alert
                        type="warning"
                        content={[
                            {
                                text: 'Para vincular tu whatsapp debes ingrear a'
                            },
                            {
                                text: 'https://broker.pulppo.com',
                                emphasize: true
                            },
                            {
                                text: 'desde tu computadora'
                            }
                        ]}
                    />
                </div>
            )}
            <div className="hidden h-full flex-col md:flex">
                <p>
                    Es importante mantener vinculado tu WhatsApp para atender con facilidad y rapidez a potenciales
                    clientes, y hacer el correcto seguimiento de tus clientes en sincronización con la plataforma
                </p>
                <div className="mb-10 mt-8">
                    <PulppoLoader loading={!qr} className="h-[300px]">
                        <img
                            alt="qr"
                            className="mx-auto"
                            src={`https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=${encodeURIComponent(qr)}`}
                        />
                    </PulppoLoader>
                </div>

                <p className="font-semibold">Vincula WhatsApp en tu computadora</p>
                <div className="mt-2 flex w-full flex-col gap-2">
                    {[
                        <p key={1} className="flex">
                            1. Abre{' '}
                            <span className="mx-1">
                                <WhatsappIcon />
                            </span>{' '}
                            WhatsApp en tu teléfono principal.
                        </p>,
                        <p key={2} className="flex flex-wrap">
                            2. Pulsa
                            <span className="mx-1">
                                <MenuIcon />
                            </span>{' '}
                            Menú o
                            <span className="mx-1">
                                <SettingsIcon />
                            </span>{' '}
                            Configuración y selecciona &quot;Dispositivos vinculados&quot;
                        </p>,
                        <p key={3} className="flex">
                            3. Toca &quot;Vincular dispositivo&quot;.
                        </p>,
                        <p key={1} className="flex">
                            4. Apunta tu teléfono hacia esta pantalla para poder escanear con tu cámara el código QR.
                        </p>
                    ].map((text, index) => (
                        <div key={index}>{text}</div>
                    ))}
                </div>
                <div className="flex flex-1 items-center justify-center">
                    <a
                        className="text-center font-semibold underline"
                        href="https://ayuda.pulppo.com/es/articles/6882186-whatsapp-recopilacion-de-datos"
                        target="_blank"
                        rel="noreferrer"
                    >
                        ¿Cómo utilizamos la información de tu WhatsApp?
                    </a>
                </div>
                <div>
                    <LoadingButton
                        type="primary"
                        className="h-11 w-full font-medium"
                        onClick={async () => {
                            await axios.delete(`${wapp_url}/${user?.uid}`);
                        }}
                    >
                        Borrar conexión y reintentar
                    </LoadingButton>
                </div>
            </div>
        </PulppoDrawer>
    );
};

export { WhatsappInactive };
