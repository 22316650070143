import { EllipsisOutlined, WarningFilled } from '@ant-design/icons';
import { List, notification } from 'antd';
import { cloneElement, ReactElement, ReactNode, useEffect, useState } from 'react';
import { PulppoDrawer } from './PulppoDrawer';
import { COLORS } from '../../utils/color';
import { twMerge } from 'tailwind-merge';

export const PulppoOptions = ({
    options,
    children,
    onChange,
    title,
    value,
    show,
    onClose,
    noChildren,
    description
}: {
    show?: boolean;
    onClose?: () => void;
    noChildren?: boolean;
    children?: ReactNode;
    title?: ReactNode;
    onChange?: (option: any) => void;
    value?: any;
    description?: string;
    options: Array<{
        label: ReactNode;
        error?: string;
        value: any;
        icon?: ReactElement;
        divider?: boolean;
        group?: string;
        isNew?: boolean;
        disabled?: boolean | string;
        description: string;
        onClick?: () => void;
        noClose?: boolean;
        information?: ReactNode;
    }>;
}) => {
    const [showOptions, setShowOptions] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setShowOptions(show);
    }, [show]);

    const closeDrawer = () => {
        setShowOptions(false);
        onClose?.();
    };

    return (
        <>
            <PulppoDrawer visible={showOptions} title={title || 'Opciones'} onClose={closeDrawer}>
                {description ? <p className="mb-6 text-pulppo-primary-gray">{description}</p> : null}
                <List loading={loading} split={false} grid={{ gutter: 10 }}>
                    {options
                        .filter((opt) => !opt.disabled)
                        .map((option, index) => {
                            if (option.error) {
                                option.icon = cloneElement(option.icon, {
                                    className: twMerge(option.icon?.props.className, 'text-gray-300')
                                });
                            }
                            return (
                                <List.Item
                                    onClick={async () => {
                                        if (option.error) return;
                                        setLoading(true);
                                        try {
                                            await option.onClick?.();
                                        } catch (err) {
                                            notification.error({
                                                message: `${err}`
                                            });
                                        } finally {
                                            setLoading(false);
                                        }
                                        onChange?.(option);
                                        if (!option.noClose) {
                                            closeDrawer();
                                        }
                                    }}
                                    className={`cursor-pointer items-start p-2 text-lg transition-colors hover:bg-gray-100 ${
                                        option.divider ? 'border-b border-solid border-gray-200' : ''
                                    }`}
                                    key={index}
                                >
                                    <div className="flex items-center justify-between gap-2">
                                        <div className="shrink-1 flex flex-1 items-start gap-4">
                                            {option.icon}
                                            <div className="flex flex-col gap-1">
                                                <p
                                                    className={twMerge(
                                                        'text-sm font-semibold',
                                                        option.error && 'text-gray-300'
                                                    )}
                                                >
                                                    {option.label as ReactNode}
                                                </p>
                                                <p
                                                    className={twMerge(
                                                        'text-sm font-normal text-pulppo-primary-gray',
                                                        option.error && 'text-gray-300'
                                                    )}
                                                >
                                                    {option.description}
                                                </p>
                                                {option.information || null}
                                            </div>
                                        </div>

                                        {option?.isNew && (
                                            <div className="h-fit shrink-0 bg-pulppo-status-light-info px-8 py-1 text-sm text-pulppo-status-dark-info">
                                                NUEVO
                                            </div>
                                        )}

                                        {option.group && (
                                            <div className="relative flex h-full px-6">
                                                <div className="flex h-5 w-5 items-center justify-center rounded-full border-2 border-solid border-black">
                                                    {option.value == value?.[option.group] && (
                                                        <div
                                                            className="h-3 w-3 rounded-full"
                                                            style={{
                                                                background: COLORS.status.dark.success
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {typeof option.error === 'string' && option?.error && (
                                        <p className="ml-12 mt-2 text-xs text-pulppo-status-dark-error">
                                            <span>
                                                <WarningFilled className="mr-2 text-pulppo-status-dark-error" />
                                            </span>
                                            {option.error}
                                        </p>
                                    )}
                                </List.Item>
                            );
                        })}
                </List>
            </PulppoDrawer>
            {noChildren ? null : (
                <>
                    {children ? (
                        cloneElement(children as any, {
                            onClick: () => setShowOptions(true)
                        })
                    ) : (
                        <div className="ml-2 flex h-9 w-full items-center justify-center border-2 bg-gray-300 text-center">
                            <EllipsisOutlined
                                onClick={() => setShowOptions(true)}
                                className="text-2xl text-pulppo-status-dark-disabled"
                            />
                        </div>
                    )}
                </>
            )}
        </>
    );
};
