import { twMerge } from 'tailwind-merge';
import { CountryCode } from 'libphonenumber-js/types';
import { AsYouType } from 'libphonenumber-js';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';
import { DownOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { PulppoSelect } from './PulppoSelect';
import es from 'react-phone-number-input/locale/es.json';

export interface IPhoneProps {
    onChange?: Function;
    value?: string;
    disabled?: boolean;
    className?: string;
}

export const PhoneInputPlain = ({ onChange, value: initialValue, disabled = false, className = '' }: IPhoneProps) => {
    if (!initialValue) initialValue = '';
    const value = `+${initialValue.replace(/\D/gi, '')}`;

    const asYouType = new AsYouType();
    asYouType.input(value);

    const selectedCountry = asYouType.getCountry() || (process.env.NEXT_PUBLIC_COUNTRY_CODE as any) || 'MX';

    const inputValue = asYouType.getNationalNumber();

    return (
        <>
            <div className="flex w-full items-center">
                <PulppoSelect
                    disabled={disabled}
                    title="Elija un código de pais"
                    value={selectedCountry}
                    options={getCountries().map((country) => ({
                        label: es[country],
                        key: country,
                        value: country,
                        icon: (
                            <img
                                width={40}
                                alt={selectedCountry}
                                style={{ minWidth: '40px' }}
                                src={`https://catamphetamine.gitlab.io/country-flag-icons/3x2/${country}.svg`}
                            />
                        )
                    }))}
                    onSelect={(item) => {
                        const prefix = `+${getCountryCallingCode(item.value as CountryCode)}`;
                        const newValue = `${prefix}${inputValue}`;
                        onChange(newValue);
                    }}
                    renderButton={(item) => (
                        <div
                            className={twMerge(
                                `flex rounded border border-solid border-gray-300 bg-white px-2 py-1 hover:border-blue-400`,
                                className
                            )}
                        >
                            <img
                                width={30}
                                alt={selectedCountry}
                                style={{ minWidth: '20px' }}
                                src={`https://catamphetamine.gitlab.io/country-flag-icons/3x2/${item.value}.svg`}
                            />
                            <DownOutlined className="ml-2 py-1 text-pulppo-status-dark-disabled" />
                        </div>
                    )}
                ></PulppoSelect>

                <Input
                    inputMode="numeric"
                    disabled={disabled}
                    onChange={async (evt) => {
                        const prefix = `+${getCountryCallingCode(selectedCountry)}`;
                        const newValue = `${prefix}${evt.currentTarget.value}`;
                        onChange(newValue);
                    }}
                    value={inputValue}
                    prefix={
                        <span className="text-secondary-color">
                            +{getCountryCallingCode(selectedCountry as CountryCode)}
                        </span>
                    }
                    className={twMerge(`ml-10px w-full py-1`, className)}
                />
            </div>
        </>
    );
};
