import { CheckOutlined, DownOutlined } from '@ant-design/icons';
import { Input, List } from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import { PulppoDrawer } from './PulppoDrawer';
import { PulppoLoader } from './PulppoLoader';
import { twMerge } from 'tailwind-merge';

interface Props<T> {
    onChange?: (value: T) => void;
    value?: T;
    placeholder?: string;
    status?: string;
    options: Array<{
        key: string;
        value: T;
        label: string;
        group?: string;
        disabled?: boolean;
        icon?: Element | ReactNode;
        description?: any;
    }>;
    loading?: boolean;
    visible?: boolean;
    emptyState?: Element | ReactNode;
    onSelect?: (option: { key: string; value: T; label: string }) => void;
    capitalizeOptions?: boolean;
    className?: string;
    title?: string;
    onButtonClick?: Function;
    filter?: any;
    allowSearch?: boolean;
    disabled?: boolean;
    onSearchChange?: (search: string) => void;
    icon?: ReactNode;
    renderButton?: (item: { key: string; value: T; label: string; icon?: ReactNode | Element }) => ReactNode | Element;
    showSelected?: boolean;
    action?: ReactNode;
}

export const PulppoSelect = <T,>({
    value,
    placeholder = 'Seleccione una opción',
    options,
    onSelect = null,
    status = null,
    className = '',
    title = 'Elija una opción',
    onButtonClick = () => {},
    allowSearch = true,
    disabled = false,
    filter = null,
    icon = null,
    loading = false,
    emptyState = null,
    renderButton,
    capitalizeOptions = false,
    onChange = () => {},
    onSearchChange = () => {},
    visible = false,
    showSelected = true,
    action = <></>
}: Props<T>) => {
    const [showDrawer, setShowDrawer] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        onSearchChange(searchTerm);
    }, [searchTerm]);

    useEffect(() => {
        setShowDrawer(visible);
    }, [visible]);

    return (
        <>
            <div
                className={`${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                onClick={() => {
                    if (disabled) return;
                    setShowDrawer(true);
                    onButtonClick();
                }}
            >
                {renderButton ? (
                    (renderButton(options?.find((opt) => opt.value === value)) as ReactNode)
                ) : (
                    <div
                        className={twMerge(
                            'flex h-10 flex-nowrap items-center justify-between overflow-x-hidden border border-solid border-gray-300 bg-white px-2 hover:border-blue-400',
                            status === 'error' && 'border-red-400',
                            disabled && 'bg-gray-100',
                            className
                        )}
                    >
                        <div id="select-label-container" className="align-center flex items-center">
                            {
                                <p
                                    className={twMerge(
                                        'truncate text-pulppo-primary-gray',
                                        options?.find((opt) => opt.value === value) && !disabled && 'text-black'
                                    )}
                                >
                                    {(showSelected && options?.find((opt) => opt.value === value)?.label) ||
                                        placeholder}
                                </p>
                            }
                        </div>

                        <div className="flex items-center justify-between">
                            {icon || <DownOutlined className="text-lg text-pulppo-secondary-gray-disabled" />}
                        </div>
                    </div>
                )}
            </div>
            <PulppoDrawer
                title={title}
                visible={showDrawer}
                onClose={() => {
                    setShowDrawer(false);
                }}
            >
                <div className="flex h-full flex-col">
                    <div className="flex-0 shrink-0">
                        {allowSearch && (
                            <Input
                                placeholder="Buscar"
                                className="mb-5"
                                value={searchTerm}
                                allowClear
                                onChange={(e) => setSearchTerm(e.currentTarget.value)}
                            />
                        )}
                        {filter}
                    </div>
                    <div className="flex-0 overflow-y-auto">
                        <PulppoLoader loading={loading}>
                            <List
                                locale={{
                                    emptyText: emptyState ? (emptyState as string) : 'No hay opciones para mostrar'
                                }}
                                dataSource={
                                    allowSearch
                                        ? options?.filter((option) =>
                                              option.label
                                                  ?.normalize('NFD')
                                                  .replace(/[\u0300-\u036f]/g, '')
                                                  ?.toLowerCase()
                                                  .includes(searchTerm?.toLowerCase())
                                          )
                                        : options
                                }
                                renderItem={(item) => {
                                    const isSelected = item.value === value;

                                    return (
                                        <List.Item
                                            className={`${item.disabled ? 'cursor-not-allowed bg-gray-100 opacity-80' : 'cursor-pointer hover:underline'} `}
                                            key={item.key}
                                            onClick={
                                                item.disabled
                                                    ? undefined
                                                    : () => {
                                                          onSelect?.(item);
                                                          setSearchTerm('');
                                                          onChange(item.value);
                                                          setShowDrawer(false);
                                                      }
                                            }
                                        >
                                            <List.Item.Meta
                                                avatar={
                                                    isSelected ? (
                                                        <CheckOutlined className="text-green-600" />
                                                    ) : (
                                                        (item.icon as ReactNode)
                                                    )
                                                }
                                                title={
                                                    <p className={capitalizeOptions ? 'capitalize' : ''}>
                                                        {item.label}
                                                    </p>
                                                }
                                                description={<p className="text-sm font-light">{item?.description}</p>}
                                            />
                                        </List.Item>
                                    );
                                }}
                            />
                        </PulppoLoader>
                    </div>
                    <div className="flex-0 flex w-full shrink-0 items-center justify-center bg-white">{action}</div>
                </div>
            </PulppoDrawer>
        </>
    );
};
