import { Spin } from 'antd';
import dayjs from 'dayjs';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

const SnowEffect = () => {
    return (
        <div className="absolute h-screen w-full overflow-hidden" style={{ zIndex: 100 }}>
            {Array.from({ length: 50 }).map((_, index) => (
                <div
                    key={index}
                    className="snowflake absolute h-4 w-4 rounded-full"
                    style={{
                        backgroundImage: 'url(./snowflake.png)',
                        backgroundSize: 'contain',
                        animationDelay: `0s`,
                        animationDuration: `${Math.random() * 5 + 3}s`,
                        left: `${Math.random() * 100}%`
                    }}
                ></div>
            ))}
        </div>
    );
};

export const PulppoLoader = ({
    loading,
    className = '',
    children = <></>
}: {
    loading: boolean;
    className?: string;
    children?: ReactNode;
}) => {
    return (
        <Spin
            className={className}
            wrapperClassName={className}
            spinning={loading}
            indicator={
                <div className="absolute left-0 top-0 z-50 m-0 flex h-full w-full items-center justify-center">
                    <div className="relative flex items-center justify-center">
                        <img
                            src={process.env.NEXT_PUBLIC_LOGO_NEGRO_SOLO || '/pulppo.png'}
                            alt="pulppo-loader"
                            style={{
                                objectFit: 'cover',
                                width: '60px',
                                height: '60px'
                            }}
                        />
                        <div className="loader absolute" />
                    </div>
                </div>
            }
        >
            {children}
        </Spin>
    );
};

export const PulppoNewLoader = ({
    loading,
    className = '',
    children = <></>
}: {
    loading: boolean;
    className?: string;
    children?: ReactNode;
}) => {
    return (
        <div className={twMerge('relative', className)}>
            {loading && (
                <>
                    <div className="absolute left-0 top-0 z-50 m-0 flex h-full w-full bg-white opacity-60"></div>
                    <div className="absolute left-0 top-0 z-50 m-0 flex h-full w-full items-center justify-center">
                        <div className="relative flex items-center justify-center pt-20">
                            <img
                                src={process.env.NEXT_PUBLIC_LOGO_NEGRO_SOLO || '/pulppo.png'}
                                alt="pulppo-loader"
                                style={{
                                    objectFit: 'cover',
                                    width: '60px',
                                    height: '60px'
                                }}
                            />
                            <div className="loader absolute" />
                        </div>
                    </div>
                </>
            )}
            {children}
        </div>
    );
};
